import { lazy, useRef } from 'react';
import { clientComponentResolver } from '#hooks/shared/clientComponentResolver/clientComponentResolver.tsx';
import { useSetCTAProps } from '#hooks/journey/useSetCTAProps/useSetCTAProps.tsx';
import { useJourneyState } from '#state/JourneyState';

const components = {
  default: lazy(() => import('./client/DeliveryReasonStep.workdigital'))
};

const Component = clientComponentResolver(components);

const DeliveryReasonStep = () => {
  const context = useJourneyState();
  const deliveryReasonRef = useRef<{ runValidations: () => boolean }>(null);

  const runValidations = (): boolean => {
    if (!deliveryReasonRef.current) return false;
    return deliveryReasonRef.current.runValidations();
  };

  useSetCTAProps(
    {
      validations: {
        runValidations
      }
    },
    [context.data.deliveryData.customerSpecification]
  );
  return <Component deliveryReasonRef={deliveryReasonRef} />;
};

export default DeliveryReasonStep;
