import type {
  TSession,
  TSessionDuplicateData,
  TUpdateSessionData,
  TWidgetCreateData,
  TWidgetSubmitData,
  TWidgetUpdateData
} from '#/types/WorkDigitalBaseTypes';
import { api } from './api';
import type { TSessionCreateResponse, TSessionDuplicateResponse, TSessionUpdateResponse } from './Response.types';
import { getSessionHash } from '#utils/sessionHash.ts';

export const createSession = async (data: TWidgetCreateData) => {
  return await api.guest.post<{ data: TSessionCreateResponse }>('/session/create', data);
};

export const updateWidgetSession = async (data: TWidgetUpdateData) => {
  return await api.guest.post<{ data: TSessionUpdateResponse }>('/session/update/widget', data);
};

export const submitWidget = async (data: TWidgetSubmitData) => {
  return await api.guest.post<{ data: TSessionUpdateResponse }>('/session/widget/submit', data);
};

export const sessionDuplicate = async (data: TSessionDuplicateData) => {
  data.sessionHash = getSessionHash() ?? '';
  return await api.guest.post<TSessionDuplicateResponse>('/session/duplicate', data);
};

export const getSession = async (sessionToken: string) => {
  return await api.guest.post<TSession>(`/session`, { sessionHash: sessionToken });
};

export const updateSession = async (data: TUpdateSessionData) => {
  return await api.guest.post<{ data: TSessionUpdateResponse }>('/session/update', data);
};

export const createOrder = async () => {
  return await api.guest.post('/order/create', { sessionHash: getSessionHash() });
};

// public async createOrder(): Promise<AxiosResponse<any>> {
//   this.setSessionToken();
//   const sessionHash = this.sessionToken;
//   return await this.http.post('/order/create', {
//     sessionHash
//   });
// }
