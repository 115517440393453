import { Box, InputError, Label, type TInputWrapperProps } from '@workdigital/component-library-react';

const CustomCheckbox = (props: TInputWrapperProps) => {
  const { htmlFor, name, label, children, errors } = props;
  return (
    <Box className={'wd-input-wrapper flex'}>
      {children}
      <Label
        className={'ml-3'}
        htmlFor={htmlFor}
        data-testid={name.toString()}
      >
        <InputError errors={errors} />
        {label}
      </Label>
    </Box>
  );
};

export default CustomCheckbox;
