import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { TProduct } from '#/types/WorkDigitalBaseTypes';
import { queryKeys } from '#/const';
import { getProducts } from '#api/common.ts';

const useProducts = (): UseQueryResult<TProduct[], void> => {
  return useQuery({
    queryKey: [queryKeys.PRODUCTS],
    queryFn: async () => {
      const response = await getProducts();
      return response.data;
    }
  });
};

export default useProducts;
