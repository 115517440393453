import { type PropsWithChildren, useEffect } from 'react';
import type { TWidgetProps } from '#/Apps/Widget/Widget.types';
import type { TCity, TStreet, TWidgetData, TWidgetState } from '#types/WorkDigitalBaseTypes.ts';
import { useState } from 'react';
import WidgetContext from './WidgetContext';
import { getCommodityConfigs } from '#configs/getConfigs.ts';
import { parsePropsWidget } from '#state/utils/parsePropsWidget.ts';

type TWidgetProviderProps = PropsWithChildren & TWidgetProps;
const WidgetProvider = ({ children, session, isMiniWidget, ...props }: TWidgetProviderProps) => {
  // const setupProps = useSetupProps(props);
  const [setupProps, setSetupProps] = useState(parsePropsWidget(props));
  const config = getCommodityConfigs(setupProps.commodity);
  const [widgetState, setWidgetState] = useState<TWidgetState>({
    isLoading: false,
    sessionCreated: false,
    forceCreateSession: false,
    cities: [] as TCity[],
    streets: [] as TStreet[],
    streetNeeded: false,
    currentClient: import.meta.env.VITE_APP_CLIENT,
    availableCommodities: {
      electricity: import.meta.env.VITE_APP_COMMODITY_ELECTRICITY,
      gas: import.meta.env.VITE_APP_COMMODITY_GAS,
      heating: import.meta.env.VITE_APP_COMMODITY_HEATING
    },
    isMiniWidget: isMiniWidget ?? false,
    clientBased: {
      elli: {
        showTariffCalculation: false
      }
    },
    created: false,
    submitError: null,
    session: session
  });

  const commodityConfig = getCommodityConfigs(setupProps.commodity);

  const { zip, city, cityId, usage, street, houseNumber } = session || {};
  const zipAndCityValue = zip && city ? `${zip} ${city}` : '';

  const [widgetData, setWidgetData] = useState<TWidgetData>({
    sessionHash: session?.sessionHash ?? undefined,
    zipAndCity: zipAndCityValue,
    streetRequired: false,
    zip: zip ?? undefined,
    city: city ?? undefined,
    cityId: cityId?.toString(),
    commodity: setupProps.commodity,
    houseNumber: houseNumber?.toString(),
    street: street ?? undefined,
    consumptionAmount: usage?.toString() ?? config.defaultStep.toString(),
    consumption: usage?.toString() ?? config.defaultStep.toString(),
    persons: commodityConfig.consumptionSteps.findIndex((item) => item == config.defaultStep).toString(),
    priceType: setupProps.priceType
  });

  useEffect(() => {
    setWidgetData((prev) => ({
      ...prev,
      zipAndCity: zipAndCityValue,
      city: city ?? undefined,
      cityId: cityId?.toString(),
      zip: zip ?? undefined
    }));
  }, [zipAndCityValue]);

  const values = {
    ...props,
    state: { widgetState, setWidgetState, setupProps, setSetupProps, commodityConfig },
    data: { widgetData, setWidgetData }
  };

  return <WidgetContext.Provider value={{ ...values }}>{children}</WidgetContext.Provider>;
};

export default WidgetProvider;
