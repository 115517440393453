import { ProductSelection } from '#JourneySteps/ProductSelection';
import { ContractStep } from '#JourneySteps/ContractStep';
import { DeliveryStep } from '#JourneySteps/DeliveryStep';
import { PaymentStep } from '#JourneySteps/PaymentStep';
import { ReviewStep } from '#JourneySteps/ReviewStep';
import type { TFunction } from 'i18next';
import type { TJourneySteps } from '#Apps/Journey/utils/client/steps.type.ts';

export const prokonSteps = (t: TFunction): TJourneySteps => {
  return {
    productChoice: {
      title: t('journey.steps.productSelection.title'),
      component: ProductSelection,
      stepNumber: 1,
      class: 'product-selection-step',
      hide: true
    },
    customerData: {
      title: t('journey.steps.customer.title'),
      component: ContractStep,
      stepNumber: 2,
      class: 'customer-data-step'
    },
    delivery: {
      title: t('journey.steps.delivery.title'),
      component: DeliveryStep,
      stepNumber: 3,
      class: 'delivery-step'
    },
    paymentData: {
      title: t('journey.steps.payment.title'),
      component: PaymentStep,
      stepNumber: 4,
      class: 'payment-data-step'
    },
    review: {
      title: t('journey.steps.review.title'),
      component: ReviewStep,
      stepNumber: 5,
      class: 'review-step'
    }
  };
};
