import type { TFunction } from 'i18next';
import { ProductSelection } from '#JourneySteps/ProductSelection';
import { ContractStep } from '#JourneySteps/ContractStep';
import { DeliveryStep } from '#JourneySteps/DeliveryStep';
import { PaymentStep } from '#JourneySteps/PaymentStep';
import { ReviewStep } from '#JourneySteps/ReviewStep';
import { elliSteps } from '#Apps/Journey/utils/client/steps.elli.ts';
import { prokonSteps } from '#Apps/Journey/utils/client/steps.prokon.ts';
import { resolveClientObject } from '#utils/client.ts';
import type { TJourneySteps } from '#Apps/Journey/utils/client/steps.type.ts';
import { DeliveryReasonStep } from '#JourneySteps/DeliveryReasonStep';
import { DeliveryAddressStep } from '#JourneySteps/DeliveryAddressStep';

export const ALL_STEPS = {
  PRODUCT: 'productChoice',
  ADDON: 'addon',
  CUSTOMER: 'customerData',
  DELIVERY: 'delivery',
  PAYMENT: 'paymentData',
  LAW: 'lawForm',
  REVIEW: 'review',
  DELIVERY_REASON: 'deliveryReason',
  DELIVERY_ADDRESS: 'deliveryAddress'
} as const;

const defaultSteps = (t: TFunction): TJourneySteps => {
  return {
    productChoice: {
      title: t('journey.steps.productSelection.title'),
      component: ProductSelection,
      stepNumber: 0,
      class: 'product-selection-step',
      hide: true,
      requiredFields: ['productCode']
    },
    deliveryReason: {
      title: t('journey.steps.deliveryReason.title'),
      component: DeliveryReasonStep,
      stepNumber: 1,
      class: 'delivery-reason-step',
      requiredFields: ['customerSpecification']
    },
    delivery: {
      title: t('journey.steps.delivery.title'),
      component: DeliveryStep,
      stepNumber: 2,
      class: 'delivery-step',
      requiredFields: ['counterNumber', 'previousProviderCodeNumber']
    },
    deliveryAddress: {
      title: t('journey.steps.deliveryAddress.title'),
      component: DeliveryAddressStep,
      stepNumber: 3,
      class: 'delivery-address-step',
      requiredFields: ['street', 'houseNumber', 'zip', 'city']
    },
    customerData: {
      title: t('journey.steps.customer.title'),
      component: ContractStep,
      stepNumber: 4,
      class: 'customer-data-step',
      requiredFields: ['firstName', 'surname', 'salutation', 'emailPrivate', 'phoneMobile', 'dateOfBirth']
    },
    paymentData: {
      title: t('journey.steps.payment.title'),
      component: PaymentStep,
      stepNumber: 5,
      class: 'payment-data-step',
      requiredFields: ['iban', 'alternativeAccountHolder']
    },
    review: {
      title: t('journey.steps.review.title'),
      component: ReviewStep,
      stepNumber: 6,
      class: 'review-step',
      requiredFields: []
    }
  };
};

const steps = {
  default: defaultSteps,
  elli: elliSteps,
  prokon: prokonSteps
};
export const getSteps = (t: TFunction): TJourneySteps => {
  const clientSteps = resolveClientObject(steps);
  return clientSteps(t);
};

export const getLastStep = (t: TFunction) => {
  const clientSteps = resolveClientObject(steps);
  return Object.values(clientSteps(t)).pop();
};
