import { lazy, useLayoutEffect, useMemo } from 'react';
import { useJourneyState } from '#state/JourneyState';
import { clientComponentResolver } from '#/hooks/shared/clientComponentResolver/clientComponentResolver';
import { getSessionHash } from '#/utils/sessionHash';
import { trackReviewPage } from '#utils/tracking.ts';
import { useSetCTAProps } from '#hooks/journey/useSetCTAProps/useSetCTAProps.tsx';
import useSession from '#/hooks/queries/useSession/useSession';

const components = {
  default: lazy(() => import('./client/ReviewStep.workdigital')),
  elli: lazy(() => import('./client/ReviewStep.elli')),
  prokon: lazy(() => import('./client/ReviewStep.prokon'))
};

const Component = clientComponentResolver(components);

const ReviewStep = () => {
  useLayoutEffect(() => {
    trackReviewPage();
  }, []);
  const context = useJourneyState();
  const sessionHash = getSessionHash();
  const { data: session } = useSession(sessionHash ?? '', context);
  if (!sessionHash) {
    throw new Error('No session token found');
  }

  const goTo = context.stepperProcess.stepperHandlers.goTo;

  const orderCreationError = useMemo(() => {
    const errors = context.formErrors;
    if (errors && errors.orderCreation) {
      return errors.orderCreation[0];
    }

    return null;
  }, [context.formErrors]);

  const runValidations = () => {
    return true;
  };

  useSetCTAProps({
    validations: {
      runValidations
    }
  });

  return (
    <Component
      data={context.data}
      currentState={context.currentState}
      extendedData={session?.extendedData}
      goTo={goTo}
      orderCreationError={orderCreationError}
    />
  );
};

export default ReviewStep;
