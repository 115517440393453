import type { TConfigs } from '#types/WorkDigitalBaseTypes.ts';

const workdigitalConfigs: Partial<TConfigs> = {
  commodities: {
    electricity: {
      consumptionSteps: [1400, 2300, 3200, 4100, 5000],
      defaultStep: 2300,
      consumptionIcon: {
        source: 'custom',
        name: 'elli-user'
      }
    }
  },
  journey: {
    discountFormInFooter: true,
    isAsideEnabled: true,
    asidePosition: 'right',
    minimumAgeRequired: 18,
    defaultProductCode: 'PRODVolkswagenNaturstromClassic',
    defaultProductName: 'Volkswagen Naturstrom Classic24',
    isBlueMeteringActive: false
  }
};

export default workdigitalConfigs;
