import { useWindowSize } from '@workdigital/component-library-react';
import { useMemo } from 'react';
import { useIsClient } from '#/hooks/shared/useIsClient';
import breakpointValues from '#root/client-themes/_breakpoints.module.scss';

// NOTE: needs to be converted into Object type
const breakpoints = breakpointValues as unknown as Record<string, string>;
const { breakpointLG, breakpointMD, breakpointXS } = breakpoints;

export const useBreakpoints = () => {
  const { width } = useWindowSize();
  const isClient = useIsClient();
  const isMobile = width <= Number(breakpoints.breakpointMD);
  const isTablet = width <= Number(breakpoints.breakpointLG);
  /**
   * NOTE: used for static known non changable values
   * We need this ta avoid to avoid any fallback (1600px)
   * from the `useWindowSize` hook
   * and rely on screen width
   *  */
  const isBelowMD = useMemo(() => {
    if (!isClient) {
      return false;
    }

    return window.innerWidth < Number(breakpointMD);
  }, [isClient, breakpointMD]);

  return {
    isMobile,
    isTablet,
    breakpointLG,
    breakpointMD,
    breakpointXS,
    isBelowMD
  };
};

export default useBreakpoints;
