export function resolveClientObject<TObject = unknown>(
  object: Record<string, TObject>,
  defaultKey = 'default'
): TObject {
  const client = import.meta.env.VITE_APP_CLIENT;
  if (client in object) {
    return object[client as keyof typeof object];
  }
  return object[defaultKey];
}

export function currentClient(): string {
  return import.meta.env.VITE_APP_CLIENT;
}
