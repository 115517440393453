import { clientComponentResolver } from '#hooks/shared/clientComponentResolver/clientComponentResolver.tsx';
import { lazy, useLayoutEffect } from 'react';
import { trackProductSelectionPage } from '#utils/tracking.ts';
import { useJourneyState } from '#state/JourneyState';
import { useSetCTAProps } from '#hooks/journey/useSetCTAProps/useSetCTAProps.tsx';

const components = {
  default: lazy(() => import('./client/ProductSelection.workdigital.tsx')),
  elli: lazy(() => import('./client/ProductSelection.elli.tsx')),
  prokon: lazy(() => import('./client/ProductSelection.prokon.tsx'))
};

const Component = clientComponentResolver(components);

const ProductSelection = () => {
  useLayoutEffect(() => {
    trackProductSelectionPage();
  }, []);

  const { data, currentState } = useJourneyState();

  const runValidations = () => {
    return data.productCode !== '' || JSON.stringify(data.productCode) !== '{}';
  };

  useSetCTAProps({
    validations: {
      runValidations
    }
  });

  return (
    <Component
      products={currentState.availableProducts}
      productsPending={currentState.productsPending}
    />
  );
};

export default ProductSelection;
