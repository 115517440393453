import { useTranslation } from 'react-i18next';
interface TCustomLabelComponentProps {
  labelKey: string;
  options?: Record<string, unknown>;
}

const CustomLabelComponent = (props: TCustomLabelComponentProps) => {
  const { t } = useTranslation();

  return (
    <span
      className={'wd-custom-checkbox-label'}
      // @ts-ignore
      dangerouslySetInnerHTML={{ __html: t(props.labelKey, { ...props.options }) }}
    />
  );
};

export default CustomLabelComponent;
