import type { TJourneySetUpProps, TWidgetProps } from '#Apps/Widget/Widget.types.ts';
export const parsePropsJourney = (props: TWidgetProps) => {
  const { redirectURLOnNOSessionToken } = props;

  const setupProps: TJourneySetUpProps = {
    redirectURLOnNOSessionToken
  };

  return setupProps;
};
