import type { TConfigs } from '#/types/WorkDigitalBaseTypes';
import { EIconSource } from '@workdigital/component-library-react';

const defaultConfigs: TConfigs = {
  commodities: {
    electricity: {
      consumptionSteps: [1000, 2000, 3000, 4000, 5000],
      defaultStep: 2000,
      consumptionIcon: {
        source: EIconSource.FLATICON,
        name: 'user'
      }
    },
    gas: {
      consumptionSteps: [1000, 2000, 3000, 4000, 5000],
      defaultStep: 2000,
      consumptionIcon: {
        source: EIconSource.FLATICON,
        name: 'house'
      }
    },
    heating: {
      consumptionSteps: [1000, 2000, 3000, 4000, 5000],
      defaultStep: 2000,
      consumptionIcon: {
        source: EIconSource.FLATICON,
        name: 'house'
      }
    }
  },
  journey: {
    isAsideEnabled: false
  },
  widget: {
    checkProductsAmount: false
  }
};

export default defaultConfigs;
