import type { TProduct, TSession } from '#types/WorkDigitalBaseTypes.ts';
import { getDiscountType } from '#utils/product.ts';

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

function initTracking() {
  window.dataLayer = window.dataLayer || [];
}

function getCommodityTitle(commodity: string): string {
  if (commodity === 'electricity') {
    return 'Stromtarif';
  } else if (commodity === 'gas') {
    return 'Gastarif';
  } else {
    return 'Stromtarif';
  }
}

function prepareEcommerceObject(session: TSession | undefined, product: TProduct) {
  const itemVariant: Record<string, string> = {
    PRODVolkswagenNaturstromClassic: '24 Monate',
    PRODVolkswagenNaturstromBlue: '12 Monate',
    FLEX_DISP: '1 Monat'
  };

  let price = 0;
  let discountCode = '';
  let discountValue = 0;
  const discountType = getDiscountType(product);
  const commodityTitle = getCommodityTitle(session?.commodity ?? '');

  if (session?.campaignOriginal) {
    discountCode = session.campaign ?? '';
    // @ts-ignore
    discountValue = product[discountType + 'Bonus'] ? product[discountType + 'Bonus'] : 0;
    price = product.totalBasePriceBonusBrutto ?? 0 - discountValue;
  } else if (session?.discountCode) {
    price = product.totalBasePriceBonusBrutto ?? 0 - discountValue;
    discountCode = session.discountCode;
  } else {
    price = product.totalBasePriceBonusBrutto ?? 0;
  }

  if (typeof price !== 'number') {
    price = parseFloat(price);
  }
  return {
    currency: 'EUR',
    value: price.toFixed(2), // value = price (-) discount
    coupon: discountCode,
    items: [
      {
        item_id: product.productCode, // “sku” oder “productID”
        item_name: product.productName,
        coupon: discountCode,
        discount: discountValue,
        index: 0,
        item_brand: 'Volkswagen Naturstrom',
        item_category: commodityTitle,
        item_variant: itemVariant[product.productCode],
        price: product.totalBasePriceBonusBrutto,
        quantity: 1
      }
    ]
  };
}

export function trackSubmitWidget(persons: string, usage: string) {
  initTracking();
  window.dataLayer?.push({
    event: 'submit_naturstrom_calculator',
    persons_in_household: persons,
    kWh_yearly: usage
  });
}

export function trackProductSelectionPage() {
  initTracking();
  window.dataLayer?.push({
    event: 'virtualPageview',
    page_title: 'Naturstrom Tarifauswahl',
    page_location: 'https://www.elli.eco/de/volkswagen/naturstrom-buchen/tarif'
  });
}

export function trackDiscountSubmit(code: string, product: string) {
  initTracking();
  window.dataLayer.push({
    event: 'submit_naturstrom_voucher',
    naturstrom_voucherID: code,
    naturstrom_tariff: product
  });
}

export function trackProductSubmit(product: string) {
  initTracking();
  window.dataLayer.push({
    event: 'submit_naturstrom_tariff',
    naturstrom_tariff: product
  });
}

export function trackBeginCheckout(session: TSession | undefined, product: TProduct) {
  window.dataLayer.push({ ecommerce: null });
  initTracking();
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: prepareEcommerceObject(session, product)
  });
}

export function trackChangeProductClick(product: string) {
  initTracking();
  window.dataLayer.push({
    event: 'change_naturstrom_tariff',
    naturstrom_tariff: product
  });
}

export function trackContractDetailsPage() {
  initTracking();
  window.dataLayer.push({
    event: 'virtualPageview',
    page_title: 'Naturstrom Buchungsstrecke: 01 - Persönliche Daten & Lieferanschrift',
    page_location: 'https://www.elli.eco/de/volkswagen/naturstrom-buchen/step1'
  });
}

export function trackDeliveryPage() {
  initTracking();
  window.dataLayer.push({
    event: 'virtualPageview',
    page_title: 'Naturstrom Buchungsstrecke: 02 - Belieferungsdetails',
    page_location: 'https://www.elli.eco/de/volkswagen/naturstrom-buchen/step2'
  });
}

export function trackCompleteDelivery(session: TSession | undefined, product: TProduct) {
  window.dataLayer.push({ ecommerce: null });
  initTracking();
  window.dataLayer.push({
    event: 'add_shipping_info',
    ecommerce: prepareEcommerceObject(session, product)
  });
}

export function trackPaymentPage() {
  initTracking();
  window.dataLayer.push({
    event: 'virtualPageview',
    page_title: 'Naturstrom Buchungsstrecke: 03 - Zahlung',
    page_location: 'https://www.elli.eco/de/volkswagen/naturstrom-buchen/step3'
  });
}

export function trackCompletePayment(session: TSession | undefined, product: TProduct) {
  window.dataLayer.push({ ecommerce: null });
  initTracking();
  window.dataLayer.push({
    event: 'add_payment_info',
    ecommerce: prepareEcommerceObject(session, product)
  });
}

export function trackRulesPage() {
  initTracking();
  window.dataLayer.push({
    event: 'virtualPageview',
    page_title: 'Naturstrom Buchungsstrecke: 04 - Rechtliche Hinweise & Abschluss',
    page_location: 'https://www.elli.eco/de/volkswagen/naturstrom-buchen/step4'
  });
}

export function trackReviewPage() {
  initTracking();
  window.dataLayer.push({
    event: 'virtualPageview',
    page_title: 'Naturstrom Buchungsstrecke: 05 - Zusammenfassung',
    page_location: 'https://www.elli.eco/de/volkswagen/naturstrom-buchen/step5'
  });
}

export function trackOrderPlaced(session: TSession | undefined, product: TProduct, orderNumber: string) {
  initTracking();
  window.dataLayer.push({
    event: 'purchase_naturstrom_tariff',
    naturstrom_tariff: product.productName,
    naturstrom_order_number: orderNumber
  });

  const ecommerce = Object.assign(
    {
      transaction_id: orderNumber,
      shipping: 0,
      tax: 0
    },
    prepareEcommerceObject(session, product)
  );

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'purchase',
    ecommerce
  });
}

export function trackSuccessPage() {
  initTracking();
  window.dataLayer.push({
    event: 'virtualPageview',
    page_title: 'Naturstrom Buchungsstrecke: Abschluss',
    page_location: 'https://www.elli.eco/de/volkswagen/naturstrom-buchen/purchase'
  });
}
