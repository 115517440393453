import useSession from '#/hooks/queries/useSession/useSession';
import { RulesForm } from '#JourneyComponents/RulesForm';
import { useJourneyState } from '#state/JourneyState';
import { getSessionHash } from '#utils/sessionHash.ts';
import { Box, Form, Typography, useForm, type TInput } from '@workdigital/component-library-react';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { trackRulesPage } from '#utils/tracking.ts';
import { useSetCTAProps } from '#hooks/journey/useSetCTAProps/useSetCTAProps.tsx';
import { useTranslation } from 'react-i18next';

const additionalFields: Record<string, TInput> = {
  smartMeter: {
    type: 'Checkbox',
    label: 'Smart Meter',
    name: 'smartMeter',
    checked: false,
    value: ''
  },
  eAuto: {
    type: 'Checkbox',
    label: 'E-Auto',
    name: 'eAuto',
    checked: false,
    value: ''
  },
  heatPump: {
    type: 'Checkbox',
    label: 'Wärmepumpe',
    name: 'heatPump',
    checked: false,
    value: ''
  }
};
const RulesData = () => {
  useLayoutEffect(() => {
    trackRulesPage();
  }, []);
  const context = useJourneyState();

  const { t } = useTranslation();

  const formRef = useRef<{ runValidations: () => boolean }>(null);
  const sessionHash = getSessionHash();
  if (!sessionHash) {
    throw new Error('No session token found');
  }
  const { data: session } = useSession(sessionHash, context);
  const extendedData = session?.extendedData || {};
  const hiddenKeys = context.data?.productCode === 'FLEX_DISP' ? ['smartMeter'] : [];

  const runValidations = () => {
    if (!formRef.current) return false;
    return formRef.current.runValidations();
  };

  useSetCTAProps(
    {
      validations: {
        runValidations
      }
    },
    context.data.rulesData
  );

  useEffect(() => {
    if (extendedData.BESTELLSTRECKE) {
      context.data.setRulesData((prev) => {
        return {
          ...prev,
          ...{
            extra: {
              smartMeter: extendedData.BESTELLSTRECKE.SMARTMETEROPT === 1,
              eAuto: extendedData.BESTELLSTRECKE.EAUTOOPT === 1,
              heatPump: extendedData.BESTELLSTRECKE.WAERMEOPT === 1
            }
          }
        };
      });
    }
  }, []);

  const { formFields } = useForm({
    fields: additionalFields,
    data: context.data.rulesData.extra,
    setData: (newData: any) => {
      context.data.setRulesData((prev) => {
        return {
          ...prev,
          ...{
            extra: newData
          }
        };
      });
    },
    filters: [
      {
        arguments: {
          hiddenKeys
        },
        type: 'hidden'
      }
    ]
  });

  return (
    <Box>
      <Box className={'step-label'}>
        <Typography as={'h2'}>{t('rules.subtitle')}</Typography>
      </Box>
      <Box>
        <RulesForm
          errors={context.formErrors}
          ref={formRef}
        />
      </Box>
      <Box className={'step-label'}>
        <Typography as={'h2'}>{t('rules.extra.subtitle')}</Typography>
      </Box>
      <Box className={'extra-rules-form'}>
        <Form fields={formFields} />
      </Box>
    </Box>
  );
};

export default RulesData;
