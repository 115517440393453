import type { TConfigs } from '../../types/WorkDigitalBaseTypes';

const workdigitalConfigs: Partial<TConfigs> = {
  commodities: {
    electricity: {
      consumptionSteps: [1500, 2500, 3500, 4500, 5500],
      defaultStep: 2500,
      consumptionIcon: {
        source: 'custom',
        name: 'elli-user'
      }
    }
  },
  journey: {
    discountFormInFooter: true,
    isAsideEnabled: true,
    minimumAgeRequired: 18,
    defaultProductCode: 'PRODVolkswagenNaturstromClassic',
    defaultProductName: 'Volkswagen Naturstrom Classic24',
    isBlueMeteringActive: false
  }
};

export default workdigitalConfigs;
