import type { Dispatch, SetStateAction } from 'react';
import type {
  TAddressData,
  TBillingInfo,
  TCustomer,
  TDeliveryData,
  TRulesData,
  TUpdateSessionData
} from '#types/WorkDigitalBaseTypes.ts';
import { format } from 'date-fns';
import { getSessionHash } from '#utils/sessionHash.ts';
import { currentClient } from '#utils/client.ts';

export const getCustomerFormData = (
  customer: TCustomer,
  billingInfo: TBillingInfo,
  addressData: TAddressData,
  extendedData: Record<string, unknown>,
  setExtendedData: Dispatch<SetStateAction<Record<string, unknown>>>
) => {
  let mergedExtendedData = {
    ...extendedData
  };

  if (currentClient() === 'prokon') {
    const collectExtraData: Record<string, any> = {};
    if (Object.keys(customer.extra).length > 0) {
      collectExtraData.Mitgliedsnummer = {
        MITGLIED: customer.extra.cooperative
      };
      if (customer.extra.cooperative === 'Mitglied') {
        collectExtraData.Mitgliedsnummer.Nummer = customer.extra.kundennummer;
      }
    }

    mergedExtendedData = {
      ...extendedData,
      ...collectExtraData
    };

    setExtendedData(mergedExtendedData);
  }

  return {
    sessionHash: getSessionHash() ?? '',
    company: customer.company,
    salutation: customer.salutation,
    firstName: customer?.firstName,
    surname: customer?.surname,
    dateOfBirth: new Date(customer?.birthday).toLocaleDateString('de-DE', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }),
    phoneMobileAreaCode: '00',
    phoneMobile: customer.phone,
    emailPrivate: customer.email,
    billingAlternativeAddress: customer.billingAlternativeAddress,
    billingCompany: billingInfo?.company,
    billingSalutation: customer?.salutation,
    billingFirstName: billingInfo?.firstName,
    billingSurname: billingInfo?.surname,
    billingStreet: billingInfo?.street,
    billingHouseNumber: billingInfo?.houseNumber,
    billingZip: billingInfo?.zip,
    billingCity: billingInfo?.city,
    billingPhoneAreaCode: '00',
    billingPhone: billingInfo?.phone,
    billingFaxAreaCode: billingInfo?.faxAreaCode,
    billingFax: billingInfo?.fax,
    billingEmail: billingInfo?.email,
    street: addressData.street,
    houseNumber: addressData.houseNumber,
    extendedData: JSON.stringify(mergedExtendedData)
  };
};

export const getDeliveryFormData = (
  deliveryData: TDeliveryData,
  productCode: string | null,
  extendedData: Record<string, unknown>,
  setExtendedData: Dispatch<SetStateAction<Record<string, unknown>>>
) => {
  let desiredDate = undefined;
  let terminationDate = undefined;
  let relocationDate = undefined;
  let basicSupplyDate = undefined;
  if (deliveryData.date) {
    switch (deliveryData.customerSpecification) {
      case 'desired_date':
        desiredDate = format(new Date(deliveryData.date), 'yyyy-MM-dd');
        break;
      case 'terminated_in_person':
        terminationDate = format(new Date(deliveryData.date), 'yyyy-MM-dd');
        break;
      case 'relocation_at':
        relocationDate = format(new Date(deliveryData.date), 'yyyy-MM-dd');
        break;
      case 'basic_supply':
        basicSupplyDate = format(new Date(deliveryData.date), 'yyyy-MM-dd');
        break;
    }
  }

  const formData: TUpdateSessionData = {
    sessionHash: getSessionHash() ?? '',
    previousProviderCodeNumber:
      typeof deliveryData?.previousProvider === 'object'
        ? deliveryData?.previousProvider?.value
        : deliveryData?.previousProvider,
    counterNumber: deliveryData?.counterNumber,
    marketLocationIdentifier: deliveryData?.marketLocationIdentifier,
    customerSpecification: deliveryData?.customerSpecification,
    desiredDate,
    terminationDate,
    relocationDate, //deliveryData?.relocationDate,
    basicSupplyDate //deliveryData?.basicSupplyDate,
  };

  if (currentClient() === 'elli') {
    let dynamicTariff: Record<string, unknown> = {};
    if (productCode === 'FLEX_DISP') {
      if (deliveryData?.extra?.smartMeter === 'maybe') {
        dynamicTariff = {
          DYNAMIC_TARIFF: {
            DYNAMIC_TARIFF_ACTIVE: 1,
            BLUE_METERING: 1
          }
        };
      } else {
        dynamicTariff = {
          DYNAMIC_TARIFF: {
            DYNAMIC_TARIFF_ACTIVE: 1
          }
        };
      }
    } else {
      // reset ext data in case FLEX_DISP was selected and switched back again
      dynamicTariff = {};
    }

    const mergedExtendedData = {
      ...extendedData,
      ...dynamicTariff
    };
    setExtendedData(mergedExtendedData);

    formData.extendedData = JSON.stringify(mergedExtendedData);
  }

  return formData;
};

export const getRulesFormData = (
  rulesData: TRulesData,
  extendedData: Record<string, unknown>,
  setExtendedData: Dispatch<SetStateAction<Record<string, unknown>>>
) => {
  const formData: Record<string, unknown> = {
    sessionHash: getSessionHash() ?? '',
    adsPhone: false,
    adsMail: rulesData.privacy,
    adsPost: false,
    adsAll: false,
    tandcs: rulesData.tandcs,
    privacy: rulesData.privacy,
    conditions: rulesData.conditions,
    conditionsDiscount: rulesData.conditionsDiscount,
    acceptTerms: rulesData.acceptTerms,
    agreeECommunication: rulesData.agreeECommunication,
    permissionToTerminateContract: rulesData.permissionToTerminateContract,
    extendedData: extendedData
    // uvpCode: null,
    // vpCode: null
  };

  if (currentClient() === 'elli') {
    formData.extendedData = {
      ...extendedData,
      BESTELLSTRECKE: {
        SMARTMETEROPT: rulesData?.extra?.smartMeter ? 1 : 0,
        EAUTOOPT: rulesData?.extra?.eAuto ? 1 : 0,
        WAERMEOPT: rulesData?.extra?.heatPump ? 1 : 0
      }
    };
    setExtendedData(formData.extendedData as Record<string, unknown>);
  }

  return formData;
};
