import { ProductSelection } from '#JourneySteps/ProductSelection';
import { ContractStep } from '#JourneySteps/ContractStep';
import { DeliveryStep } from '#JourneySteps/DeliveryStep';
import { PaymentStep } from '#JourneySteps/PaymentStep';
import { RulesStep } from '#JourneySteps/RulesStep';
import { ReviewStep } from '#JourneySteps/ReviewStep';
import type { TFunction } from 'i18next';
import type { TJourneySteps } from '#Apps/Journey/utils/client/steps.type.ts';

export const elliSteps = (t: TFunction): TJourneySteps => {
  return {
    productChoice: {
      title: t('journey.steps.productSelection.title'),
      component: ProductSelection,
      stepNumber: 1,
      class: 'product-selection-step',
      requiredFields: ['productCode']
    },
    customerData: {
      title: t('journey.steps.customer.title'),
      component: ContractStep,
      stepNumber: 2,
      class: 'customer-data-step',
      requiredFields: [
        'firstName',
        'surname',
        'salutation',
        'emailPrivate',
        'phoneMobile',
        'dateOfBirth',
        'street',
        'houseNumber',
        'zip',
        'city'
      ]
    },
    delivery: {
      title: t('journey.steps.delivery.title'),
      component: DeliveryStep,
      stepNumber: 3,
      class: 'delivery-step',
      requiredFields: ['customerSpecification', 'counterNumber', 'previousProviderCodeNumber']
    },
    paymentData: {
      title: t('journey.steps.payment.title'),
      component: PaymentStep,
      stepNumber: 4,
      class: 'payment-data-step',
      requiredFields: ['iban', 'alternativeAccountHolder']
    },
    lawForm: {
      title: t('journey.steps.rules.title'),
      component: RulesStep,
      stepNumber: 5,
      class: 'law-form-step',
      requiredFields: ['adsPhone', 'adsMail', 'adsPost', 'extendedData', 'uvpCode', 'vpCode']
    },
    review: {
      title: t('journey.steps.review.title'),
      component: ReviewStep,
      stepNumber: 6,
      class: 'review-step',
      requiredFields: []
    }
  };
};
