import type { FC } from 'react';
import type { AppType } from '#types/WorkDigitalBaseTypes.ts';
import { useMemo } from 'react';
import type { TAppResolverProps } from './AppResolver.types.ts';
import React from 'react';
import LoadingComponent from '#/components/LoadingComponent/LoadingComponent.tsx';
import JourneyProvider from '#/state/JourneyState/JourneyProvider.tsx';
import WidgetProvider from '#state/WidgetState/WidgetProvider.tsx';
import omit from 'lodash-es/omit';
import { usePreloadErrorHandler } from '#hooks/shared/usePreloadErrorHandler';

const AppResolver: FC = () => {
  usePreloadErrorHandler();
  const appProps = useMemo(() => {
    const appType = (import.meta.env.VITE_APP_TYPE as AppType) ?? 'Widget';
    const props = window.workdigital ?? {
      app: appType
    };
    if (!props.app) {
      props.app = appType;
    }

    Object.keys(props).forEach((key) => {
      const lowerKey = key.toLowerCase();
      if (key !== lowerKey) {
        props[lowerKey] = props[key];
        delete props[key];
      }
    });

    return {
      app: props.app,
      [props.app.toLowerCase()]: omit(props, ['app'])
    } as unknown as TAppResolverProps;
  }, [window.workdigital]);

  if (typeof window === 'undefined' || !window.localStorage) {
    return <>Please allow data storage in your browser </>;
  }

  const { app, ...restProps } = appProps;
  if (app === 'Journey') {
    const Journey = React.lazy(() => import('../Journey/Journey.tsx'));
    return (
      <React.Suspense fallback={<LoadingComponent />}>
        <JourneyProvider {...restProps.journey}>
          <Journey />
        </JourneyProvider>
      </React.Suspense>
    );
  } else if (app === 'Widget') {
    const Widget = React.lazy(() => import('../Widget/Widget.tsx'));
    return (
      <React.Suspense fallback={<LoadingComponent />}>
        <WidgetProvider {...appProps.widget}>
          <Widget />
        </WidgetProvider>
      </React.Suspense>
    );
  }

  return <>Something went wrong</>;
};

export default AppResolver;
