import type { UseQueryResult } from '@tanstack/react-query';
import type { TSettings } from '#/types/WorkDigitalBaseTypes';
import { useQuery } from '@tanstack/react-query';
import { getSettings } from '#api/common';
import { queryKeys } from '#/const';

const useStreets = (): UseQueryResult<TSettings, void> => {
  return useQuery({
    queryKey: [queryKeys.SETTINGS],
    queryFn: async () => {
      const response = await getSettings();
      return response.data;
    }
  });
};

export default useStreets;
