import type { TConfigs } from '../../types/WorkDigitalBaseTypes';
import { EIconSource } from '@workdigital/component-library-react';

const workdigitalConfigs: Partial<TConfigs> = {
  commodities: {
    electricity: {
      consumptionSteps: [1000, 2000, 3000],
      defaultStep: 2000,
      consumptionIcon: {
        source: EIconSource.FLATICON,
        name: 'user'
      }
    },
    gas: {
      consumptionSteps: [1000, 2000, 3000],
      defaultStep: 2000,
      consumptionIcon: {
        source: EIconSource.FLATICON,
        name: 'house'
      }
    },
    heating: {
      consumptionSteps: [1000, 2000, 3000],
      defaultStep: 2000,
      consumptionIcon: {
        source: EIconSource.FLATICON,
        name: 'house'
      }
    }
  }
};

export default workdigitalConfigs;
