import { CustomLabelComponent } from '#/components/CustomLabelComponent';
import type { TInput } from '@workdigital/component-library-react';
import { CustomCheckbox } from '#/components/CustomCheckbox';

const tariffLinks: Record<'Classic24' | 'Blue' | 'Flex', string> = {
  Classic24: 'https://www.elli.eco/de/naturstrom/preis-und-lieferbedingungen_classic.pdf',
  Blue: 'https://www.elli.eco/de/naturstrom/preis-und-lieferbedingungen_blue.pdf',
  Flex: 'https://www.elli.eco/downloads/de/naturstrom/preis-und-lieferbedingungen_flex.pdf'
};

export const getElliFields: (productName?: string) => Record<string, TInput> = (productName) => ({
  privacy: {
    type: 'Checkbox',
    name: 'privacy',
    checked: false,
    value: '',
    label: <CustomLabelComponent labelKey={'privacy.label'} />,
    inputWrapper: CustomCheckbox
  },
  tandcs: {
    type: 'Checkbox',
    name: 'tandcs',
    checked: false,
    value: '',
    label: (
      <CustomLabelComponent
        labelKey={'tandcs.label'}
        options={{
          tariffName: productName,
          tariffLink: tariffLinks[((productName || '').split(' ').pop() || '') as keyof typeof tariffLinks]
        }}
      />
    ),
    inputWrapper: CustomCheckbox,
    validations: [
      {
        validationKey: 'tandcs',
        strategy: 'submit',
        validate: (value) => {
          return Boolean(value);
        },
        messages: ['Bitte akzeptieren Sie diesen rechtlichen Hinweis']
      }
    ]
  },
  agreeSmartMeter: {
    type: 'Checkbox',
    name: 'agreeSmartMeter',
    checked: false,
    value: '',
    label: <CustomLabelComponent labelKey={'smartmeter.agree.label'} />,
    inputWrapper: CustomCheckbox,
    validations: [
      {
        validationKey: 'agreeSmartMeter',
        strategy: 'submit',
        validate: (value) => {
          return Boolean(value);
        },
        messages: ['Bitte akzeptieren Sie diesen rechtlichen Hinweis']
      }
    ]
  }
});

export const elliOrder: string[] = ['tandcs', 'privacy', 'agreeSmartMeter'];
