import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { TProvider } from '#/types/WorkDigitalBaseTypes';
import { queryKeys } from '#/const';
import { getProviders } from '#api/common.ts';

const useProviders = (): UseQueryResult<TProvider[], void> => {
  return useQuery({
    queryKey: [queryKeys.PROVIDERS],
    queryFn: async () => {
      const response = await getProviders();
      return response.data;
    }
  });
};

export default useProviders;
