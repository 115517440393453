import type { TProduct } from '#types/WorkDigitalBaseTypes.ts';

export function getSelectedProduct(availableProducts: TProduct[], productCode: string) {
  return availableProducts.find((product) => product.productCode === productCode) ?? ({} as TProduct);
}

export function getDiscountType(product: TProduct) {
  const instantBonus: number = product.instantBonus as number;
  const recurringBonus: number = product.recurringBonus as number;
  const switchingBonus: number = product.switchingBonus as number;

  if (instantBonus > 0) {
    return 'instant';
  }
  if (recurringBonus > 0) {
    return 'recurring';
  }
  if (switchingBonus > 0) {
    return 'switching';
  }
  return 'credit';
}
