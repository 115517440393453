export function getSessionHash() {
  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      return localStorage.getItem('wd-session-token');
    }
  } catch (e) {
    console.error('Error getting session hash from local storage', e);
  }
  return null;
}

export function setSessionHash(sessionHash: string) {
  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.setItem('wd-session-token', sessionHash);
    }
  } catch (e) {
    console.error('Error setting session hash in local storage', e);
  }
}

export function removeSessionHash() {
  localStorage.removeItem('wd-session-token');
}
