import * as Sentry from '@sentry/react';

const replay = Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false });

export const initSentry = () =>
  Sentry.init({
    environment: import.meta.env.VITE_APP_STAGE + '_' + import.meta.env.VITE_APP_CLIENT,
    dsn: 'https://b7bcb3a4cebe6bded533a8c793c3b21e@o364294.ingest.sentry.io/4506853147475968',
    integrations: [Sentry.browserTracingIntegration(), replay],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: [import.meta.env.VITE_WORKDIGITAL_API_BASE_URL, import.meta.env.VITE_APP_BASE_URL],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

if (import.meta.env.VITE_ACTIVATE_SENTRY_REPLAY === 'active') {
  replay.flush();
}
