import { lazy, useRef } from 'react';
import { clientComponentResolver } from '#hooks/shared/clientComponentResolver/clientComponentResolver.tsx';
import { useSetCTAProps } from '#hooks/journey/useSetCTAProps/useSetCTAProps.tsx';
import { useJourneyState } from '#state/JourneyState';
import useSession from '#hooks/queries/useSession/useSession.tsx';
import { getSessionHash } from '#utils/sessionHash.ts';

const components = {
  default: lazy(() => import('./client/DeliveryAddressStep.workdigital'))
};

const Component = clientComponentResolver(components);

const DeliveryAddressStep = () => {
  const context = useJourneyState();

  const { data: session } = useSession(getSessionHash() ?? '', context);

  const deliveryAddressRef = useRef<{ runValidations: () => boolean }>(null);

  if (session && session.streetFixed) {
    context.stepperProcess.stepperHandlers.next();
  }

  const runValidations = (): boolean => {
    if (!deliveryAddressRef.current) return false;
    return deliveryAddressRef.current.runValidations();
  };

  useSetCTAProps(
    {
      validations: {
        runValidations
      }
    },
    [context.data.addressData.street, context.data.addressData.houseNumber]
  );

  return <Component deliveryAddressRef={deliveryAddressRef} />;
};

export default DeliveryAddressStep;
