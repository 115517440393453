import { CenterContainer, ProgressSpinner } from '@workdigital/component-library-react';

const LoadingComponent = () => {
  return (
    <CenterContainer>
      <ProgressSpinner />
    </CenterContainer>
  );
};

export default LoadingComponent;
