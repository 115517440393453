import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { TJourneyContext, TSession } from '#/types/WorkDigitalBaseTypes';
import { queryKeys } from '#/const';
import { getSession } from '#api/session.ts';
import { AxiosError } from 'axios';
import { removeSessionHash } from '#utils/sessionHash.ts';

const parseString = (entry?: string) => {
  if (!entry) {
    return null;
  }

  return JSON.parse(entry);
};

const useSession = (sessionHash: string, context: TJourneyContext): UseQueryResult<TSession, void> => {
  return useQuery({
    queryKey: [queryKeys.SESSION, sessionHash],
    queryFn: async () => {
      let response;
      try {
        response = await getSession(sessionHash);
        response.data.extendedData = parseString(response.data.extendedData);
        return response.data;
      } catch (error: unknown) {
        // if we got a 403 error, that means session-has is wrong.
        // We need to remove the session hash and reload the page
        if (error instanceof AxiosError && error?.response?.status === 403) {
          removeSessionHash();
          if (context.currentState.setupProps.redirectURLOnNOSessionToken) {
            window.location.href = context.currentState.setupProps.redirectURLOnNOSessionToken;
          } else {
            window.location.reload();
          }
        }
      }
    }
  });
};

export default useSession;
