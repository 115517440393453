import Cookies from 'js-cookie';
import { useEffect } from 'react';

/** Fixing TypeError: Failed to fetch dynamically imported module */
const usePreloadErrorHandler = () => {
  function shouldHardRefresh() {
    const COOKIE_EXPIRATION_TIME = 1 / 96; // 15 minutes
    const cookie = Cookies.get('wd-no-hard-refresh');
    if (!cookie) {
      Cookies.set('wd-no-hard-refresh', 'true', { expires: COOKIE_EXPIRATION_TIME });
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const handlePreloadError = (event: Event) => {
      event.preventDefault();
      if (shouldHardRefresh()) {
        window.location.reload();
      }
    };

    window.addEventListener('vite:preloadError', handlePreloadError);

    return () => {
      window.removeEventListener('vite:preloadError', handlePreloadError);
    };
  }, []);
};

export default usePreloadErrorHandler;
