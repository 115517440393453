import type { TInput } from '@workdigital/component-library-react';

export const prokonFields: Record<string, TInput> = {
  birthday: {
    id: 'birthday',
    type: 'MultiInput',
    size: 6,
    label: 'Geburtsdatum',
    name: 'birthday',
    value: {},
    inputs: {
      day: {
        label: 'Tag',
        name: 'day',
        value: '',
        type: 'Text',
        size: 4
      },
      month: {
        label: 'Monat',
        name: 'month',
        value: '',
        type: 'Text',
        size: 4
      },
      year: {
        label: 'Jahr',
        name: 'year',
        value: '',
        type: 'Text',
        size: 4
      }
    }
  }
};
