import { lazy, useRef } from 'react';
import { clientComponentResolver } from '#hooks/shared/clientComponentResolver/clientComponentResolver.tsx';
import { useJourneyState } from '#state/JourneyState';
import { useBreakpoints } from '#hooks/shared/useBreakpoints';
import type { TFormErrors } from '@workdigital/component-library-react';
import { useSetCTAProps } from '#hooks/journey/useSetCTAProps/useSetCTAProps.tsx';

const components = {
  default: lazy(() => import('./client/ContractStep.elli')),
  // elli: lazy(() => import('./client/ContractStep.elli')),
  prokon: lazy(() => import('./client/ContractStep.prokon'))
};

const Component = clientComponentResolver(components);

const ContractStep = () => {
  const { isBelowMD } = useBreakpoints();
  const contractFormRef = useRef<{ runValidations: () => boolean }>(null);
  const contractAddressFormRef = useRef<{ runValidations: () => boolean }>(null);
  const billingFormRef = useRef<{ runValidations: () => boolean }>(null);
  const additionalFormsRef = useRef<Record<string, { runValidations: () => boolean }>>({});

  const context = useJourneyState();

  const isBillingAlternativeAddress = context.data.customer.billingAlternativeAddress;

  const runValidations = (): boolean => {
    if (
      !contractFormRef.current ||
      !contractAddressFormRef.current ||
      (isBillingAlternativeAddress && !billingFormRef.current)
    )
      return false;
    const contractValid = contractFormRef.current.runValidations();
    const contractAddressValid = contractAddressFormRef.current.runValidations();
    const billingValid = isBillingAlternativeAddress ? (billingFormRef?.current?.runValidations() as boolean) : true;

    let additionalFormsValid = true;
    if (Object.keys(additionalFormsRef.current).length > 0) {
      Object.keys(additionalFormsRef.current).forEach((key) => {
        if (!additionalFormsRef.current[key].runValidations()) {
          additionalFormsValid = false;
        }
      });
    }

    return contractValid && contractAddressValid && billingValid && additionalFormsValid;
  };

  useSetCTAProps(
    {
      validations: {
        runValidations
      }
    },
    [isBillingAlternativeAddress]
  );

  return (
    <Component
      isBelowMD={isBelowMD}
      contractFormRef={contractFormRef}
      contractAddressFormRef={contractAddressFormRef}
      billingFormRef={billingFormRef}
      addtionalFormsRef={additionalFormsRef}
      errors={context.formErrors as TFormErrors}
    />
  );
};

export default ContractStep;
