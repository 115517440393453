import type { TInput } from '@workdigital/component-library-react';
import { prokonFields } from './client/RulesForm.fields.prokon.ts';
import { elliFields, elliOrder } from './client/RulesForm.fields.elli.tsx';
import type { TFormFieldsProps } from '#types/WorkDigitalBaseTypes.ts';
import { CustomLabelComponent } from '#/components/CustomLabelComponent';
import { CustomCheckbox } from '#/components/CustomCheckbox';
import { resolveFormFields } from '#utils/form.ts';

const defaultFields = (props: TFormFieldsProps): Record<string, TInput> => {
  const { t } = props;
  return {
    tandcs: {
      type: 'Checkbox',
      name: 'tandcs',
      checked: false,
      value: '',
      label: (
        <CustomLabelComponent
          labelKey={'tandcs.label'}
          options={{
            agb: t('agb.url'),
            privacyPolicy: t('privacy.policy.url'),
            cancellationPolicy: t('cancellation.policy.url')
          }}
        />
      ),
      inputWrapper: CustomCheckbox,
      validations: [
        {
          validationKey: 'tandcs',
          strategy: 'submit',
          validate: (value) => {
            return Boolean(value);
          },
          messages: [t('required')]
        }
      ]
    },
    adsAll: {
      type: 'Checkbox',
      name: 'adsAll',
      value: '',
      checked: false,
      label: <CustomLabelComponent labelKey={'adsAll.label'} />,
      inputWrapper: CustomCheckbox
    }
  };
};

//@todo make a better type for this
const defaultOrder: string[] = ['tandcs', 'adsAll'];

const fields = {
  default: defaultFields,
  prokon: prokonFields,
  elli: elliFields
};

const orders = {
  default: defaultOrder,
  elli: elliOrder
};

export const getFormFields = (props: TFormFieldsProps) => {
  return resolveFormFields(fields, orders, props);
};
