import type { TFunction } from 'i18next';
import { ProductSelection } from '#JourneySteps/ProductSelection';
import { ContractStep } from '#JourneySteps/ContractStep';
import { DeliveryStep } from '#JourneySteps/DeliveryStep';
import { PaymentStep } from '#JourneySteps/PaymentStep';
import { RulesStep } from '#JourneySteps/RulesStep';
import { ReviewStep } from '#JourneySteps/ReviewStep';
import { elliSteps } from '#Apps/Journey/utils/client/steps.elli.ts';
import { prokonSteps } from '#Apps/Journey/utils/client/steps.prokon.ts';
import { resolveClientObject } from '#utils/client.ts';
import type { TJourneySteps } from '#Apps/Journey/utils/client/steps.type.ts';

export const ALL_STEPS = {
  PRODUCT: 'productChoice',
  ADDON: 'addon',
  CUSTOMER: 'customerData',
  DELIVERY: 'delivery',
  PAYMENT: 'paymentData',
  LAW: 'lawForm',
  REVIEW: 'review'
} as const;

const defaultSteps = (t: TFunction): TJourneySteps => {
  return {
    productChoice: {
      title: t('journey.steps.productSelection.title'),
      component: ProductSelection,
      stepNumber: 1,
      class: 'product-selection-step',
      hide: true
    },
    customerData: {
      title: t('journey.steps.customer.title'),
      component: ContractStep,
      stepNumber: 2,
      class: 'customer-data-step'
    },
    delivery: {
      title: t('journey.steps.delivery.title'),
      component: DeliveryStep,
      stepNumber: 3,
      class: 'delivery-step'
    },
    paymentData: {
      title: t('journey.steps.payment.title'),
      component: PaymentStep,
      stepNumber: 4,
      class: 'payment-data-step'
    },
    lawForm: {
      title: t('journey.steps.rules.title'),
      component: RulesStep,
      stepNumber: 5,
      class: 'law-form-step'
    },
    review: {
      title: t('journey.steps.review.title'),
      component: ReviewStep,
      stepNumber: 6,
      class: 'review-step'
    }
  };
};

const steps = {
  default: defaultSteps,
  elli: elliSteps,
  prokon: prokonSteps
};
export const getSteps = (t: TFunction): TJourneySteps => {
  const clientSteps = resolveClientObject(steps);
  return clientSteps(t);
};

export const getLastStep = (t: TFunction) => {
  const clientSteps = resolveClientObject(steps);
  return Object.values(clientSteps(t)).pop();
};
