import type { TConfigs } from '../../types/WorkDigitalBaseTypes';
import { EIconSource } from '@workdigital/component-library-react';

const workdigitalConfigs: Partial<TConfigs> = {
  commodities: {
    electricity: {
      consumptionSteps: [1000, 2000, 3000, 4000],
      defaultStep: 2000,
      consumptionIcon: {
        source: EIconSource.FLATICON,
        name: 'user'
      }
    },
    gas: {
      consumptionSteps: [1000, 2000, 3000, 4000],
      defaultStep: 2000,
      consumptionIcon: {
        source: EIconSource.FLATICON,
        name: 'house'
      }
    },
    heating: {
      consumptionSteps: [1000, 2000, 3000, 4000],
      defaultStep: 2000,
      consumptionIcon: {
        source: EIconSource.FLATICON,
        name: 'house'
      }
    }
  },
  journey: {
    hideCtaFromFirstStep: true,
    discountFormInFooter: false,
    isAsideEnabled: true,
    asidePosition: 'left',
    minimumAgeRequired: 18,
    defaultProductCode: 'PRODVolkswagenNaturstromClassic',
    defaultProductName: 'Volkswagen Naturstrom Classic24',
    recommendedProductCode: 'work_digital_12',
    isBlueMeteringActive: false
  }
};

export default workdigitalConfigs;
