import { resolveClientObject } from '#/utils/client';
import type { ComponentRef, FC, ForwardedRef, LazyExoticComponent } from 'react';
import { Suspense, forwardRef } from 'react';

// export const dynamicImport = async (componentFolderPath: string, clientName: string) => {
//   if (!componentFolderPath.startsWith('/')) {
//     componentFolderPath = `/${componentFolderPath}`;
//   }
//   try {
//     return await import(`${componentFolderPath}/client/${clientName}.tsx`);
//   } catch (error) {
//     return await import(`${componentFolderPath}/client/default.tsx`);
//   }
// };

// const clientComponentResolver = <TProps,>(componentFolderPath: string, useRef = false) => {
//   const client = import.meta.env.VITE_APP_CLIENT;
//   const ResolvedComponent = lazy(async () => dynamicImport(componentFolderPath, client));

//   let DynamicComponent;
//   if (useRef) {
//     DynamicComponent = forwardRef<TProps>((props, ref) => (
//       <Suspense fallback={<></>}>
//         <ResolvedComponent
//           {...props}
//           ref={ref}
//         />
//       </Suspense>
//     ));
//   } else {
//     DynamicComponent = (props: TProps) => (
//       <Suspense fallback={<></>}>
//         <ResolvedComponent {...props} />
//       </Suspense>
//     );
//   }

//   return DynamicComponent;
// };

type TLazyClientComponent<TProps> =
  | LazyExoticComponent<FC<TProps>>
  | LazyExoticComponent<(props: TProps) => JSX.Element>;

const clientComponentResolver = <TProps,>(components: Record<string, TLazyClientComponent<TProps>>) => {
  const Component = resolveClientObject(components);
  return forwardRef((props: TProps, ref: ForwardedRef<ComponentRef<TLazyClientComponent<TProps>>>) => {
    const innerProps = props ?? ({} as TProps);
    return (
      <Suspense fallback={<></>}>
        {/* @ts-ignore */}
        <Component
          {...innerProps}
          ref={ref}
        />
      </Suspense>
    );
  });
};

export { clientComponentResolver };
