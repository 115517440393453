import type { TInput } from '@workdigital/component-library-react';
import { prokonFields } from './client/RulesForm.fields.prokon.ts';
import { getElliFields, elliOrder } from './client/RulesForm.fields.elli.tsx';
import { resolveClientObject } from '#utils/client.ts';
import { orderObjectByArrayKeys } from '#utils/object.ts';

const defaultFields: Record<string, TInput> = {
  privacy: {
    type: 'Checkbox',
    name: 'privacy',
    checked: false,
    value: '',
    label: 'something here'
  }
};

//@todo make a better type for this
const defaultOrder: string[] = ['privacy'];

const getFields = (productName?: string) => ({
  default: defaultFields,
  prokon: prokonFields,
  elli: getElliFields(productName)
});

const orders = {
  default: defaultOrder,
  elli: elliOrder
};

export const getFormFields = (productName?: string) => {
  const fields = getFields(productName);
  // here you could now tweak the order of the fields,
  // based on the current client of simply do something like we did with configs
  return orderObjectByArrayKeys({ ...defaultFields, ...resolveClientObject(fields) }, resolveClientObject(orders));
};
