import { resolveClientObject } from '#utils/client.ts';
import { orderObjectByArrayKeys } from '#utils/object.ts';
import type { TInput } from '@workdigital/component-library-react';
import type { TFormFieldsProps } from '#types/WorkDigitalBaseTypes.ts';

interface TFieldsObject {
  default: Record<string, TInput> | ((props: TFormFieldsProps) => Record<string, TInput>);
  [key: string]: any;
}

type TOrdersObject = {
  default: string[];
  [key: string]: any;
};

export const resolveFormFields = (fields: TFieldsObject, orders: TOrdersObject, props: TFormFieldsProps) => {
  let clientFields = resolveClientObject(fields);
  if (typeof clientFields === 'function') {
    clientFields = clientFields(props);
  }
  let defaultFields = fields.default;
  if (typeof fields.default === 'function') {
    defaultFields = fields.default(props);
  }
  let clientOrder = resolveClientObject(orders);
  if (typeof clientOrder === 'function') {
    clientOrder = clientOrder(props);
  }
  return orderObjectByArrayKeys({ ...defaultFields, ...clientFields }, clientOrder);
};
