import { clientComponentResolver } from '#/hooks/shared/clientComponentResolver/clientComponentResolver';

import { lazy, useLayoutEffect, useRef } from 'react';
import { trackPaymentPage } from '#utils/tracking.ts';
import { useJourneyState } from '#state/JourneyState';
import { useSetCTAProps } from '#hooks/journey/useSetCTAProps/useSetCTAProps.tsx';
import { currentClient } from '#utils/client.ts';

const components = {
  default: lazy(() => import('./client/PaymentStep.workdigital')),
  elli: lazy(() => import('./client/PaymentStep.elli')),
  prokon: lazy(() => import('./client/PaymentStep.prokon'))
};

const Component = clientComponentResolver(components);
const PaymentStep = () => {
  useLayoutEffect(() => {
    trackPaymentPage();
  }, []);
  const context = useJourneyState();
  const formRef = useRef<{ runValidations: () => boolean }>(null);
  const billingRef = useRef<{ runValidations: () => boolean }>(null);

  const validationsWorkdigital = () => {
    let formValid = true;
    let billingValid = true;
    let IBANValid = true;
    if (context.data.paymentData.directDebit) {
      if (!formRef.current) return false;
      formValid = formRef.current.runValidations();
      if (context.data.paymentData.directDebit) {
        if (context.data.paymentData.bic === '' || context.data.paymentData.bank === '') {
          IBANValid = false;
        }
      }
    }
    if (context.data.customer.billingAlternativeAddress) {
      if (!billingRef.current) return false;
      billingValid = billingRef.current.runValidations();
    }

    return formValid && billingValid && IBANValid;
  };

  const runValidations = () => {
    if (currentClient() === 'workdigital') {
      return validationsWorkdigital();
    }
    if (!formRef.current) return false;
    const formValid = formRef.current.runValidations();
    let IBANValid = true;
    if (context.data.paymentData.directDebit) {
      if (context.data.paymentData.bic === '' || context.data.paymentData.bank === '') {
        IBANValid = false;
      }
    }

    return formValid && IBANValid;
  };

  useSetCTAProps(
    {
      validations: {
        runValidations
      }
    },
    [context.data.paymentData, context.data.customer.billingAlternativeAddress]
  );

  return (
    <Component
      errors={context.formErrors}
      formRef={formRef}
      billingFormRef={billingRef}
    />
  );
};

export default PaymentStep;
