import { clientComponentResolver } from '#/hooks/shared/clientComponentResolver/clientComponentResolver';

import { lazy, useLayoutEffect, useRef } from 'react';
import { trackPaymentPage } from '#utils/tracking.ts';
import { useJourneyState } from '#state/JourneyState';
import { useSetCTAProps } from '#hooks/journey/useSetCTAProps/useSetCTAProps.tsx';

const components = {
  default: lazy(() => import('./client/PaymentStep.workdigital')),
  elli: lazy(() => import('./client/PaymentStep.elli')),
  prokon: lazy(() => import('./client/PaymentStep.prokon'))
};

const Component = clientComponentResolver(components);
const PaymentStep = () => {
  useLayoutEffect(() => {
    trackPaymentPage();
  }, []);
  const context = useJourneyState();
  const formRef = useRef<{ runValidations: () => boolean }>(null);

  const runValidations = () => {
    if (!formRef.current) return false;
    const formValid = formRef.current.runValidations();
    let IBANValid = true;
    if (context.data.paymentData.directDebit) {
      if (context.data.paymentData.bic === '' || context.data.paymentData.bank === '') {
        IBANValid = false;
      }
    }

    return formValid && IBANValid;
  };

  useSetCTAProps(
    {
      validations: {
        runValidations
      }
    },
    [context.data.paymentData]
  );

  return (
    <Component
      errors={context.formErrors}
      formRef={formRef}
    />
  );
};

export default PaymentStep;
