import { useJourneyState } from '#state/JourneyState';
import React, { lazy, useLayoutEffect, useRef, useState } from 'react';
import { trackDeliveryPage } from '#utils/tracking.ts';
import { clientComponentResolver } from '#hooks/shared/clientComponentResolver/clientComponentResolver.tsx';
import { currentClient } from '#utils/client.ts';
import { useSetCTAProps } from '#hooks/journey/useSetCTAProps/useSetCTAProps.tsx';

const components = {
  default: lazy(() => import('./client/DeliveryStep.elli')),
  // elli: lazy(() => import('./client/ContractStep.elli')),
  prokon: lazy(() => import('./client/DeliveryStep.prokon'))
};

const Component = clientComponentResolver(components);
const DeliveryData = () => {
  useLayoutEffect(() => {
    trackDeliveryPage();
  }, []);
  const context = useJourneyState();

  const formRef = useRef<{ runValidations: () => boolean }>(null);

  const setSmartMeter = (value: string) => {
    context.data.setDeliveryData((prev) => {
      return {
        ...prev,
        extra: {
          smartMeter: value
        }
      };
    });
  };

  const [errorSmartMeter, setErrorSmartMeter] = useState(false);

  const runValidations = () => {
    if (!formRef.current) return false;
    const deliveryValid = formRef.current.runValidations();
    let smartMeterValid = true;
    if (currentClient() === 'elli') {
      if (context.data.productCode === 'FLEX_DISP' && !context.data.deliveryData?.extra?.smartMeter) {
        smartMeterValid = false;
        setErrorSmartMeter(true);
      }
    }
    return deliveryValid && smartMeterValid;
  };

  useSetCTAProps(
    {
      validations: {
        runValidations
      }
    },
    [context.data.deliveryData]
  );

  const handleClickSmartMeter = (event: React.MouseEvent<Element, MouseEvent>, value: string) => {
    const target = event.target as HTMLDivElement;
    if (target && !target.classList.contains('close-icon')) {
      setSmartMeter(value);
    }
  };

  return (
    <Component
      formRef={formRef}
      errors={context.formErrors}
      handleClickSmartMeter={handleClickSmartMeter}
      smartMeter={context.data.deliveryData.extra?.smartMeter}
      setSmartMeter={setSmartMeter}
      errorSmartMeter={errorSmartMeter}
    />
  );
};

export default DeliveryData;
