import { useJourneyState } from '#state/JourneyState';
import { useEffect } from 'react';
type ValidationObject = { runValidations: () => boolean };
export type TCTAProps = {
  validations: ValidationObject;
  makeUpdateBeforeCreateOrder?: boolean;
};

export const useSetCTAProps = (props: TCTAProps, deps?: any) => {
  const context = useJourneyState();
  const stringiFy = JSON.stringify(props);
  const stringDeps = JSON.stringify(deps);
  useEffect(() => {
    // @ts-ignore
    context.cta.setCtaProps(props);
  }, [stringiFy, stringDeps]);
};
