import type { TJourneyContext } from '#/types/WorkDigitalBaseTypes';
import { useContext } from 'react';
import JourneyContext from './JourneyContext';

const useJourneyState = () => {
    //@TODO implement proper default object so no casting is required
    return useContext(JourneyContext) as unknown as TJourneyContext;
};

export default useJourneyState;
