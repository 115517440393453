import type { TConfigs } from '#types/WorkDigitalBaseTypes.ts';

const prokonConfigs: Partial<TConfigs> = {
  commodities: {
    electricity: {
      consumptionSteps: [1300, 2400, 3200, 4000],
      defaultStep: 2400,
      consumptionIcon: {
        source: 'custom',
        name: 'elli-user'
      }
    }
  },
  journey: {
    nextStepButtonInsideSteps: true,
    withMiniWidget: true,
    isAsideEnabled: false,
    asideAtTop: true,
    minimumAgeRequired: 18,
    recommendedProductCode: 'Prokon_Windstrom_12_2025',
    recommendedProductName: 'Prokon Windstrom 12'
    // defaultProductCode: 'PRODVolkswagenNaturstromClassic'
  },
  widget: {
    checkProductsAmount: true
  }
};

export default prokonConfigs;
